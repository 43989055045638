
<template>
  <div class="wrapper" :class="{ wrapperFix : isFilter}">
    <!-- <loading :active="firstLoading" :is-full-page="true" :lock-scroll="true" :color='color'></loading> -->
    <loading :active="firstLoading" :is-full-page="true" :lock-scroll="true" :color='color'></loading>
    <header>
      <h1 class="hidden md:block">
        <a href="/"><img class="logo" src="@/assets/image/mainPage/joymap-logo-01.webp" alt="logo" /></a>
      </h1>
      <div class="junction"></div>
      <div class="fixBar"></div>
      <div class="h-full">
        <h2 class="text-22 text-white text-center pb-3">快速尋找餐廳，即刻享樂優惠!</h2>
        <div class="flex justify-center bookingOptions">
          <div class="bookingOption">
            <p class="flex items-center">
              <img src="@/assets/image/mainPage/filter-pin.png" alt="location icon" />地點
            </p>
            <select name="location" id="location" v-model="storeData.city_id">
              <option v-for="(value, key) in cityList" :key="value" :value="value">{{key}}</option>
            </select>
          </div>
          <div class="bookingOption">
            <p class="flex items-center">
              <img src="@/assets/image/mainPage/filter-person.png" alt="person icon" />人數
            </p>
            <select name="number" id="number" v-model="storeData.total_people">
              <option v-for="num in 10" :value="num" :key="num">{{num}}</option>
            </select>
          </div>
          <div class="bookingOption">
            <p class="flex items-center">
              <img src="@/assets/image/mainPage/filter-date.png" alt="date icon" />日期
            </p>
            <div class="datePicker cursor-pointer">
              <input type="text" class="cursor-pointer" :value='storeData.date' @click="pickerDate" readonly>
              <img src="@/assets/image/mainPage/drop-down@2x.webp" alt="arrow down icon" class=" absolute" />
              <transition enter-active-class="transition ease-out duration-300 transform" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition ease-in duration-200 transform" leave-to-class="opacity-0" v-show="isPickerDate" class="datePickerTrans origin-top-right absolute rounded-md shadow-lg ">
                <div class="rounded-md bg-white shadow-lg">
                  <DatePicker v-model="date" :min-date="new Date()" />
                </div>
              </transition>
            </div>

          </div>
        </div>
      </div>
    </header>
    <div class="text-center infoBox">
      <p>「享樂地圖」服務上線！支付回饋享樂幣</p>
      <p class=" font-medium ">享樂幣等同現金，幣值1：1</p>
    </div>

    <div class="listSectionWrap">
      <div class="listSection">
        <div class="tagsSection ">
          <p v-if=" tempSelectedPromotion.length === 0 && tempSelectedFood_types.length === 0 ">可透過標籤快速查找餐廳！</p>
          <div>
            <div class="tagsWrap" v-if=" storeData.tags.length !== 0 || tempSelectedFood_types.length !== 0 ">
              <div class="tagsCont">
                <div class="tagsState tagsBar">
                  <span class="selectedTagStyle mbTagWidth mr-2 mb-1" v-for="evenTag in tempSelectedPromotion" :key="evenTag">
                    <img src="@/assets/image/mainPage/tag-03.png" alt="tag icon" />
                    {{evenTag.title}}</span>
                  <span class="selectedTagStyle mbTagWidth mr-2 mb-1" v-for="tag in tempSelectedFood_types" :key="tag">
                    <img src="@/assets/image/mainPage/tag-03.png" alt="tag icon" />
                    {{tag.name}}</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <button @click="openTagModal" v-if="storeData.food_types.length === 0 && storeData.tags.length === 0"><i class="fas fa-tag pr-2"></i>篩選</button>
            <button @click="openTagModal" class="hidden md:block" v-if="storeData.food_types.length !== 0 || storeData.tags.length !== 0 "><i class="fas fa-pencil-alt pr-2 "></i>編輯標籤</button>
            <button @click="openTagModal" class="w-9 h-9 md:hidden" v-if=" storeData.food_types.length !== 0 || storeData.tags.length !== 0 "><i class="fas fa-pencil-alt"></i></button>
          </div>
        </div>

        <div class="storeLists md:flex flex-wrap items-center ">
          
          <template v-for="store in stores" :key="store.store_id">
            <div class="storeCard">
              <div class="feedback" v-if="store.back_percent">
                <img class="rewards-tag" src="@/assets/image/feedback-empty.png" />
                <div class="num">{{ store.back_percent }}</div>
              </div>
              
              <!-- <img class="rewards-tag" src="@/assets/image/feedback-8@2x.png" /> -->
              <!-- <img class="rewards-tag" src="@/assets/image/feedback-20@2x.png" v-if="store.tags.indexOf('享樂幣回饋20%') != -1 " /> -->

              <p class="unable-jcoin" v-if="store.can_pay !== 1">無法折抵享樂幣</p>

              <div class="store-img-wrap">
                <img class="w-full h-full object-cover storeImage cursor-pointer" @click="jumpToStore(store.slug)" :src="store.logo" alt="store image">
              </div>
              <div class="storeInfo ">
                <div class="flex justify-between items-center py-2 ">
                  <p class="storeName cursor-pointer" @click="jumpToStore(store.slug)">{{store.name}}</p>
                  <span class="storeCity">
                    <!-- <i class="fas fa-map-marker-alt pr-2"></i>{{currentCity}} -->
                    <i class="fas fa-map-marker-alt pr-2"></i>{{store.district_name}}
                  </span>
                </div>
                <div class="flex items-center w-full py-1" v-if="store.can_order_times.length > 0">
                  <img class="arrow-left" src="@/assets/image/mainPage/back.png" alt="left arrow" @click="swipeLeft(store)" />
                  <div class="timeOptions flex items-center w-full" id="scroll-bar" :ref="store.name">
                    <span class="unavaliableTimes" v-for="time in store.can_order_times" :key="time" @click="clickTime(time,store)" :class="{timeOption: store.can_order_times.find((item) => item === time )}">{{time}}</span>
                  </div>
                  <img class="arrow-right" src="@/assets/image/mainPage/next.png" alt="right arrow" @click="swipeRight(store)" />
                </div>
                <div class="emptyTime cursor-pointer" v-if="store.can_order_times.length === 0" @click="jumpToStore(store.slug)">
                  <p>本日已額滿，請選擇其他日期</p>
                </div>
              </div>
            </div>
          </template>
          <!-- 
          <div class="storeCard" v-if="!isLoading">
            <div class="store-img-wrap">
              <img class="w-full h-full object-cover storeImage " src="@/assets/image/Joymap(380X183).jpg" alt="大人的享樂派對" @click="jumpToEvent">
            </div>

            <div class="storeInfo ">
              <div class="flex justify-between items-center py-2">
                <a @click="jumpToEvent" title="大人的享樂派對" class="storeName text-main cursor-pointer">大人的享樂派對</a>
                <span class="storeCity">AD</span>
              </div>
              <div class="flex items-center">
                <p class="eventContent">在 Joymap 餐廳消費，使用享樂支付，享 20% 超高回饋，多重優惠爆噴，美味直射你心!</p>
              </div>
            </div>
          </div> -->
          <p class="text-center w-full text-grayColor-dark mt-10" v-if="stores.length === 0 && !firstLoading">暫無符合之餐廳，請重新搜尋</p>
        </div>

        <div class="vld-parent">
          <loading :color='color' :height="25" :lock-scroll="false" :is-full-page="isFullPage" :z-index="999999" :blur="blur" :opacity="opacity" :active="isLoading"></loading>
          <p class="endText text-center" v-if="noResult && stores.length !== 0">已經到底囉!</p>
        </div>
      </div>
    </div>

    <transition enter-active-class="transition ease-out duration-300 transform" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition ease-in duration-200 transform" leave-from-class="opacity-100" leave-to-class="opacity-0" v-show="isFilter">
      <div ref="modal-backdrop" class="fixed z-10 inset-0 overflow-y-auto filterModalOuter" v-if="isFilter">
        <div class="filterModal">
          <div class="filterModalBody w-full" role="dialog" ref="modal">
            <div class="relative">
              <img class="absolute modalClose" src="@/assets/image/mainPage/close_small.png" alt="colse" @click="closeModal" />
              <h4 class="mb-5 text-center text-lg font-medium">選擇標籤</h4>
            </div>

            <div>
              <div v-if="allPromotion.length > 0">
                <h5 class="mb-2">活動標籤</h5>
                <div class="flex items-center flexWrap">
                  <span class="selectedTagStyle mr-2 mb-2" v-for="eventTag in allPromotion" :key="eventTag.id" @click.stop="clickEventType(eventTag)" :class="{active: selectedPromotion.find((item) => item.id === eventTag.id), }">
                    <i class="fas fa-tag pr-2"></i>
                    <!-- <img src="@/assets/image/mainPage/tag-02.png" alt="tag icon" /> -->
                    {{eventTag.title}}</span>
                </div>
              </div>
              <div class="my-5">
                <h5 class="mb-2">料理類型</h5>
                <div class="flex items-center flexWrap">
                  <span class="selectedTagStyle mr-2 mb-2" v-for="typeTag in allFoodTypes" :key="typeTag.id" @click.stop="clickFoodType(typeTag)" :class="{active: selectedFood_types.find((item) => item.id === typeTag.id), }">
                    <!-- <img src="@/assets/image/mainPage/tag-02.png" alt="tag icon" /> -->
                    <i class="fas fa-tag pr-2"></i>
                    {{typeTag.name}}
                  </span>
                </div>
              </div>
            </div>
            <!-- <div class="flex justify-center">
              <button class="bg-main text-white" @click="filterTags">篩選</button>
            </div> -->
          </div>
          <div class="flex justify-center filterTagBtn">
            <button class="bg-main text-white" @click="filterTags">篩選</button>
          </div>
        </div>

      </div>
    </transition>
  </div>

  <footer class="bg-white md:flex flex-col justify-center items-center p-5 hidden ">
    <img src="@/assets/image/mainPage/joymap-logo-02.webp" alt="logo" class="mb-5">
    <ul class="flex justify-center text-main pb-5 font-medium text-xs md:text-base">
      <li class="px-3"><a href="" target="_blank" title="關於我們">關於我們</a></li>
      <li class="px-3"><a href="/privacy-policy" target="_blank" title="隱私政策">隱私政策</a></li>
      <li class="px-3"><a href="/terms-of-service" target="_blank" title="服務條款">使用條款</a></li>
    </ul>
    <p class="text-xs text-grayColor-darkest  pb-5">
      Copyright 2021 Joymap Ltd. All rights reserved.
    </p>
    <ul class="flex text-xs text-grayColor-darkest ">
      <li class="px-3">Powered by</li>
      <li class="px-3 text-main "><a href="" target="_blank" title="JoyMap">JoyMap</a></li>
      <li class="px-3">Version 1.0.0</li>
    </ul>
  </footer>

</template>

<script>
import { Calendar, DatePicker } from "v-calendar";
import { mapState, mapActions } from "vuex";
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Main",
  components: {
    Calendar,
    DatePicker,
    Loading,
  },

  data() {
    return {
      firstLoading: false,
      color: "#ff5923",
      blur: "0px",
      opacity: 0,
      isFullPage: false,
      isMounted: false,
      isLoading: false,
      stores: [],
      allPromotion: [],
      allFoodTypes: [],
      tempSelectedPromotion: [],
      tempSelectedFood_types: [],
      selectedPromotion: [],
      selectedFood_types: [],
      isFilter: false,
      isPickerDate: false,
      date: new Date(),
      storeData: {
        city_id: 1,
        total_people: 2,
        date: '',
        limit: 50,
        page: 1,
        tags: [],
        food_types: [],
      },
      cityList: {},
      noResult: false,
      currentCity: "",
      isEnd: false,
    };
  },
  watch: {
    'storeData.total_people': function (val) {
      this.changeSearchData({ ...this.searchData, total_people: val });
      this.clickDate();
      // localStorage.setItem("adultNum", val);
    },
    'storeData.city_id': function (val) {
      this.changeSearchData({ ...this.searchData, city_id: val })
      this.clickDate();
      // localStorage.setItem("city_id", val);
    },
    date: function (val) {
      const date = moment(val).format("YYYY-MM-DD");
      this.storeData.date = date;
      this.changeSearchData({ ...this.searchData, date: date })
      this.clickDate();
      // localStorage.setItem("joyDate", date);
    },
  },
  methods: {
    ...mapActions(["changeSearchData"]),
    getCookie() {
      this.$store.dispatch("getCookie");
    },
    getMember() {
      this.$store.dispatch("getMember");
    },
    jumpToStore(slug) {
      const link = `${process.env.VUE_APP_JOYMAP_URL}/store/${slug}`;
      // let screenWidth = document.body.clientWidth;

      this.$router.push(`/store/${slug}`);
    },
    jumpToEvent() {
      const link = "https://www.joymap.tw/event/adultjoyparty/";
      let screenWidth = document.body.clientWidth;

      if (screenWidth < 500) {
        setTimeout(function () {
          location.replace(`https://www.joymap.tw/event/adultjoyparty/`);
        }, 250);
      } else {
        window.open(link, "_blank");
      }
    },
    openTagModal() {
      this.selectedFood_types = [];
      this.selectedPromotion = [];

      this.tempSelectedFood_types.forEach((item) => {
        this.selectedFood_types.push(item);
      });

      this.tempSelectedPromotion.forEach((item) => {
        this.selectedPromotion.push(item);
      });

      this.isFilter = !this.isFilter;
    },
    async handleScroll() {
      let scrollHeight = window.scrollY;
      let maxHeight =
        window.document.body.scrollHeight -
        window.document.documentElement.clientHeight;
      let scrollTop = document.documentElement.scrollTop;

      if (scrollTop >= maxHeight - 100) {
        if (this.isLoading == false) {
          if (!this.isEnd) {
            await this.getStores();
          }
        }
      }
    },
    clickDate() {
      if (this.isLoading) return;
      // const vm = this;
      this.storeData.page = 1;
      // this.storeData.limit = 1;
      this.transferCity();
      const api = `${process.env.VUE_APP_API}/store/lists`;
      this.isLoading = true;
      this.firstLoading = true;

      this.$http
        .post(api, this.storeData)
        .then((response) => {
          // this.stores = [];
          this.stores = response.data.return.datas;
          this.isLoading = false;
          this.firstLoading = false;
          this.noResult = false;
          this.isPickerDate = false;
          if (response.data.return.datas.length === 0) {
            this.isEnd = true;
          }
        })
        .catch((err) => {
          this.noResult = false;
          this.isLoading = false;
          this.firstLoading = false;
        });
    },
    async getStores() {
      const vm = this;
      const date = moment(this.storeData.date).format("YYYY-MM-DD");
      this.storeData.date = date;
      const api = `${process.env.VUE_APP_API}/store/lists`;
      this.isLoading = true;
      this.storeData.page++;

      let res = null;
      try {
        res = await this.$http.post(api, this.storeData);
        vm.isLoading = false;
        this.noResult = true;
        if (res.data.return.datas.length > 0) {
          this.stores.push(...res.data.return.datas);
        } else {
          this.isEnd = true;
        }
      } catch (err) {
        this.noResult = true;
        vm.isLoading = false;
      }
    },
    getEvents() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/tag/all-frontend`;
      // const api = "http://api-joymap.dennytwdd.cf/tag/all-frontend";
      // vm.loading = true;
      vm.$http.get(api).then((response) => {
        vm.allPromotion = response.data.return.datas;
        // vm.loading = false;
      });
    },
    getFoodTypes() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/food-type/all-frontend`;
      vm.$http
        .get(api)
        .then((response) => {
          vm.allFoodTypes = response.data.return.datas;
        })
        .catch((error) => {
          if (error.response.data) {
            console.log(error.response.data);
          }
        });
    },
    pickerDate() {
      this.isPickerDate = !this.isPickerDate;
    },
    clickFoodType(tag) {
      let newIndex = -1;
      if (this.selectedFood_types.length !== 0) {
        this.selectedFood_types.find((item, index) => {
          if (item.id === tag.id) {
            newIndex = index;
          }
        });
      }
      if (newIndex === -1) {
        this.selectedFood_types.push(tag);
        // this.storeData.food_types.push(tag.id);
      } else {
        this.selectedFood_types.splice(newIndex, 1);
        // this.storeData.food_types.splice(newIndex, 1);
      }
    },
    clickEventType(tag) {
      let newIndex = -1;
      if (this.selectedPromotion.length !== 0) {
        this.selectedPromotion.find((item, index) => {
          if (item.id === tag.id) {
            newIndex = index;
          }
        });
      }

      if (newIndex === -1) {
        this.selectedPromotion.push(tag);
        // this.storeData.tags.push(tag.id);
      } else {
        this.selectedPromotion.splice(newIndex, 1);
        // this.storeData.tags.splice(newIndex, 1);
      }
    },
    filterTags() {
      this.storeData.food_types = [];
      this.tempSelectedFood_types = [];
      this.storeData.tags = [];
      this.tempSelectedPromotion = [];

      this.selectedFood_types.forEach((item) => {
        this.storeData.food_types.push(item.id);
        this.tempSelectedFood_types.push(item);
      });

      this.selectedPromotion.forEach((item) => {
        this.storeData.tags.push(item.id);
        this.tempSelectedPromotion.push(item);
      });

      this.isFilter = false;
      this.clickDate();
      this.selectedFood_types = [];
      this.selectedPromotion = [];
    },
    scrollTo(element, scrollPixels, duration) {
      const scrollPos = element.scrollLeft;
      // Condition to check if scrolling is required
      if (
        !(
          (scrollPos === 0 || scrollPixels > 0) &&
          (element.clientWidth + scrollPos === element.scrollWidth ||
            scrollPixels < 0)
        )
      ) {
        // Get the start timestamp
        const startTime =
          "now" in window.performance
            ? performance.now()
            : new Date().getTime();

        function scroll(timestamp) {
          //Calculate the timeelapsed
          const timeElapsed = timestamp - startTime;
          //Calculate progress
          const progress = Math.min(timeElapsed / duration, 1);
          //Set the scrolleft
          element.scrollLeft = scrollPos + scrollPixels * progress;
          //Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
          if (timeElapsed < duration) {
            //Request for animation
            window.requestAnimationFrame(scroll);
          } else {
            return;
          }
        }
        //Call requestAnimationFrame on scroll function first time
        window.requestAnimationFrame(scroll);
      }
    },
    swipeLeft(store) {
      const content = this.$refs[store.name];
      this.scrollTo(content, -75, 300);
    },
    swipeRight(store) {
      const content = this.$refs[store.name];
      this.scrollTo(content, 75, 300);
    },
    clickTime(time, store) {
      const year = moment(this.storeData.date).format("YYYY");
      let month = moment(this.storeData.date).format("MM");
      let newMonth = month.replace(/\b(0+)/gi, "");

      const day = moment(this.storeData.date).format("D");
      const date = moment(this.storeData.date).day();
      // this.$store.commit("VALUE", this.date);
      localStorage.setItem("adultNum", this.storeData.total_people);
      // localStorage.setItem("chineseDay", chineseDate);
      localStorage.setItem("clickDay", day);
      localStorage.setItem("clickMonth", newMonth);
      localStorage.setItem("clickYear", year);
      localStorage.setItem("clickWeek", date);
      localStorage.setItem("orderTime", time);
      localStorage.setItem("tempValue", this.date);

      this.$store.commit("TEMPVALUE", this.date);
      this.$router.push(`/store/${store.slug}/info`);
      // window.open(routeUrl.href, "_blank");
    },
    closeModal() {
      this.selectedFood_types = [];
      this.selectedPromotion = [];
      this.isFilter = false;
    },
    getUrlAddress() {
      let encodedStr = location.href;
      let url = decodeURI(encodedStr);
      // let url = "https://web-test.joymap.tw/restaurants?addr=桃園市中壢區春德路105號&lat=25.077688827804632&lon=121.57481968402863&zip=114";
      let addr;
      let id;
      if (url.indexOf("?") != -1) {
        let ary = url.split("?")[1].split("&");
        id = ary[0].split("=")[1];
        addr = decodeURI(id);
        let cityName = addr.slice(0, 3);
        for (let [key, value] of Object.entries(this.cityList)) {
          if (key === cityName) {
            this.storeData.city_id = value;
            this.transferCity();
            this.clickDate();
          }
        }
      }
    },
    transferCity() {
      if (this.storeData.city_id === 1) {
        this.currentCity = "台北市";
      } else if (this.storeData.city_id === 2) {
        this.currentCity = "新北市";
      } else if (this.storeData.city_id === 3) {
        this.currentCity = "基隆市";
      } else if (this.storeData.city_id === 4) {
        this.currentCity = "桃園市";
      } else if (this.storeData.city_id === 5) {
        this.currentCity = "新竹縣";
      } else if (this.storeData.city_id === 6) {
        this.currentCity = "新竹市";
      } else if (this.storeData.city_id === 7) {
        this.currentCity = "苗栗縣";
      } else if (this.storeData.city_id === 8) {
        this.currentCity = "台中市";
      } else if (this.storeData.city_id === 9) {
        this.currentCity = "南投縣";
      } else if (this.storeData.city_id === 10) {
        this.currentCity = "彰化縣";
      } else if (this.storeData.city_id === 11) {
        this.currentCity = "雲林縣";
      } else if (this.storeData.city_id === 12) {
        this.currentCity = "嘉義縣";
      } else if (this.storeData.city_id === 13) {
        this.currentCity = "嘉義市";
      } else if (this.storeData.city_id === 14) {
        this.currentCity = "台南市";
      } else if (this.storeData.city_id === 15) {
        this.currentCity = "高雄市";
      } else if (this.storeData.city_id === 16) {
        this.currentCity = "屏東縣";
      } else if (this.storeData.city_id === 17) {
        this.currentCity = "宜蘭縣";
      } else if (this.storeData.city_id === 18) {
        this.currentCity = "花蓮縣";
      } else if (this.storeData.city_id === 19) {
        this.currentCity = "台東縣";
      } else if (this.storeData.city_id === 20) {
        this.currentCity = "澎湖縣";
      } else if (this.storeData.city_id === 21) {
        this.currentCity = "金門縣";
      } else if (this.storeData.city_id === 22) {
        this.currentCity = "連江縣";
      }
    },
  },
  computed: { 
    ...mapState(['searchData']),
    member() {
      return this.$store.state.TWDDMember;
    },
  },
  mounted() {
    window.scrollTo(0, 0);

    this.storeData = { ...this.storeData, ...this.searchData };
    this.date = this.searchData.date;
    this.$http.get("/cityID.json?20221222").then((response) => {
      this.cityList = response.data;
      this.getUrlAddress();
      this.clickDate();
    });
    this.getCookie();
  },
  created() {
    // this.firstLoading = true;
    window.addEventListener("scroll", this.handleScroll);
    // setTimeout(() => {
    //   window.addEventListener("scroll", this.handleScroll);
    //   // this.firstLoading = false;
    // }, 1500);

    this.getEvents();
    this.getFoodTypes();
    this.transferCity();
  },
};
</script>

<style lang="scss" scoped>
.vld-parent {
  position: relative;
  .vld-overlay {
    bottom: 50px;
  }
}
.father-box {
  height: 800px; /* 父元素一定要指定高度 */
  overflow-y: scroll; /* 一定要制定父元素超出滚动 */
}
header {
  height: 207px;
  background-image: linear-gradient(to bottom, #fa6c3e 0%, #ff8623);
}
.wrapper {
  background: #edf1f5;
  min-height: calc(100vh - 192px);
}
.wrapperFix {
  position: fixed;
  width: 100%;
}

.logo {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 99999;
}
.junction {
  height: 10px;
  width: 100%;
  background-color: #ff5923;
  position: fixed;
  top: 0px;
  z-index: 9999;
}

@media (min-width: 1200px) {
  .junction {
    position: static;
  }
}

.fixBar {
  height: 54px;
  background-image: linear-gradient(to bottom, #ff5923, rgba(255, 89, 35, 0));
  position: sticky;
  top: 0;
  z-index: 9989;
}

.bookingOptions {
  img {
    padding-right: 3px;
  }
  .bookingOption {
    width: 110px;
    margin: 0px 5px;
  }
  p {
    color: white;
    font-size: 13px;
    font-weight: 500;
    padding-bottom: 3px;
  }
  select {
    box-sizing: border-box;
    font-size: 15px;
    width: 114px;
    height: 40px;
    padding: 6px 8px;
    border-radius: 8px;
    background-color: #fff;
    text-align: center;
    text-align-last: center;
    -moz-text-align-last: center;
  }
  input {
    box-sizing: border-box;
    width: 114px;
    height: 40px;
    padding: 6px 8px;
    border-radius: 8px;
    background: #fff;
  }
}

.bookingOptions {
  @media (min-width: 834px) {
    .bookingOption {
      width: 170px;
      margin: 0px 5px;
    }
    select {
      width: 170px;
      margin-right: 5px;
    }
    input {
      width: 170px;
    }
  }
}
.infoBox {
  background-color: #103f93;
  color: white;
  font-size: 15px;
  font-weight: 300;
  padding: 5px;
}
.listSectionWrap {
  overflow: hidden;
  background-color: #edf1f5;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 261px);
}

@media (min-width: 768px) {
  .listSectionWrap {
    min-height: calc(100vh - 453px);
  }
}

.listSection {
  width: 100%;
  padding: 15px 20px;
}

@media (min-width: 1024px) {
  .listSection {
    padding: 15px 121px;
  }
}
@media (min-width: 1200px) {
  .listSection {
    padding: 15px 100px;
  }
}
@media (min-width: 1400px) {
  .listSection {
    width: 1440px;
    padding: 15px 121px;
  }
}
.tagsSection {
  margin: 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    color: #a0b1b9;
    font-size: 15px;
  }
  button {
    height: 36px;
    padding: 6px 10px;
    border-radius: 8px;
    background: #fa6c3e;
    color: white;
    font-weight: 500;
  }
  span {
    border: solid 1px #fa6c3e;
    color: #fa6c3e;
  }
}

@media (min-width: 768px) {
  .tagsSection {
    margin: 0px 10px;
    button {
      width: 115px;
    }
  }
}
@media (min-width: 1024px) {
  .tagsSection {
    margin: 0px 13px 0 10px;
  }
}
.selectedTagStyle {
  cursor: pointer;
  padding: 6px 10px;
  border-radius: 8px;
  border: solid 1px #a0b1b9;
  color: #a0b1b9;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    padding-right: 3px;
  }
}

.selectedTagStyle.active {
  color: #fa6c3e;
  border: solid 1px #fa6c3e;
}

.tagsBar {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  overflow-x: auto;
  height: 90px;
  // padding: 10px 0;
}

@media (min-width: 768px) {
  .tagsBar {
    width: 100%;
    height: auto;
  }
}

.storeCard {
  width: 100%;
  background: white;
  border-radius: 10px;
  margin: 12px 0;
  box-sizing: border-box;
  position: relative;
  .store-img-wrap {
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .unable-jcoin {
    position: absolute;
    right: 10px;
    top: 120px;
    color: #fff;
    background: rgba(0, 0, 0, 0.555);
    border-radius: 5px;
    font-size: 15px;
    padding: 8px 15px;
    @media (min-width: 768px) {
      top: 140px;
    }
  }

  .storeImage {
    height: 166px;
    cursor: pointer;
  }
  .storeName {
    font-size: 18px;
    font-weight: 600;
    max-width: 250px;
  }
}

@media (min-width: 1024px) {
  .storeCard {
    .storeName {
      max-width: 270px;
    }
  }
}

@media (min-width: 1200px) {
  .storeCard {
    .storeName {
      max-width: 220px;
    }
  }
}

@media (min-width: 1400px) {
  .storeCard {
    .storeName {
      max-width: 270px;
    }
  }
}

@media (min-width: 768px) {
  .storeCard {
    width: 48%;
    margin: 10px 7px;
    .storeImage {
      height: 190px;
    }
  }
}

@media (min-width: 768px) {
  .storeCard {
    width: 48%;
    margin: 10px 7px;
    .storeImage {
      height: 190px;
    }
  }
}

@media (min-width: 1200px) {
  .storeCard {
    // width: 48%;
    width: 31.6%;
    margin: 10px 7px;
  }
}

@media (min-width: 1400px) {
  .storeCard {
    width: 31.6%;
    margin: 10px 10px;
    .storeImage {
      height: 183px;
    }
  }
}

.storeInfo {
  // height: 138px;
  min-height: 111px;
  padding: 2px 10px 12px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .storeCity {
    color: #a0b1b9;
    font-size: 15px;
    font-weight: 600;
  }
}
.timeOptions {
  overflow: auto;
  white-space: nowrap;
  font-size: 15px;
  font-weight: 600;
  padding: 4px 0;
}

.unavaliableTimes {
  height: 23px;
  min-width: 55px;
  line-height: 23px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  background: #a0b1b9;
  pointer-events: none;
  color: #fff;
  border: none;
  // padding: 0px 10px;
  margin: 0 7px;
  border-radius: 0.375rem;
  text-align: center;
}

.timeOption {
  background: #fa6c3e;
  pointer-events: auto;
  cursor: pointer;
  width: 55px;
  text-align: center;
}

@media (min-width: 768px) {
  .timeOption {
    margin: 0 3px;
    padding: 0px 10px;
  }
}
@media (min-width: 1024px) {
  .timeOption {
    margin: 0 5px;
    padding: 0px 10px;
  }
}
@media (min-width: 1200px) {
  .timeOption {
    margin: 0 6px;
    padding: 0px 11px;
  }
}

.arrow-left {
  margin-right: 2px;
  cursor: pointer;
}
.arrow-right {
  margin-left: 2px;
  cursor: pointer;
}

.filterModalOuter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9999;
}
.filterModal {
  display: flex;
  flex-wrap: wrap;
  // overflow-y: auto;
  width: 345px;
  // max-height: 493px;
  margin: 12px 15px 100px;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  position: relative;

  h5 {
    font-size: 15px;
  }
  button {
    width: 157px;
    height: 40px;
    padding: 9px 62px 9px 63px;
    border-radius: 8px;
    background-color: #fa6c3e;
    // position: absolute;
    // bottom: 20px;
  }
}

@media (min-width: 768px) {
  .filterModal {
    width: 525px;
  }
}

.filterModalBody {
  height: 450px;
  overflow-y: auto;
}

.modalClose {
  right: 10px;
  cursor: pointer;
}
.filterTagBtn {
  background: #fff;
  width: 100%;
  padding: 20px 0 0 0;
}

.tagsWrap {
  overflow: hidden;
  height: 60px;
  width: 280px;
}
.tagsCont {
  height: 60px;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
}
.tagsState {
  left: 0px;
  top: 0px;
  position: absolute;
  height: 60px;
  white-space: nowrap;
  display: flex;
}

@media (min-width: 414px) {
  .tagsWrap {
    width: 330px;
  }
}
@media (min-width: 768px) {
  .tagsWrap {
    width: 600px;
  }
}
@media (min-width: 1024px) {
  .tagsWrap {
    width: 620px;
  }
}
@media (min-width: 1400px) {
  .tagsWrap {
    width: 1043px;
  }
}

.eventContent {
  font-size: 15px;
  color: #2b2b2b;
}

.datePicker {
  cursor: pointer;
  position: relative;
  z-index: 10;
  img {
    position: absolute;
    right: 0;
    top: 16px;
    width: 14px;
  }
}
.datePickerTrans {
  margin-top: 5px;
  right: 0px;
}
.endText {
  font-size: 15px;
  font-weight: 600;
  color: #a0b1b9;
  padding: 50px 0 20px 0;
}


.feedback{
  position: absolute;
  right: 0;
  top: 0;
  // width: 100%;
  .num{
    font-size: 19px;
    color: white;
    position: absolute;
    bottom: 16%;
    right: 54%;
    font-weight:bolder;
    // font-style:italic;
    opacity: .85;
    transform:skewX(-11deg) rotate(-9deg);
  }
}

.rewards-tag {
  // position: absolute;
  // right: 0;
  // top: 0;
  width: 95px;
  height: 65px;
}
// .rewards-circle {
//   background: #fa6c3e;
//   color: #fff;
//   border-radius: 100%;
//   min-width: 36px;
//   min-height: 36px;
//   position: absolute;
//   top: 10px;
//   left: 10px;
//   text-align: center;
//   line-height: 36px;
//   font-size: 13px;
//   font-weight: 500;
//   z-index: 5;
// }
.emptyTime {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5px 0;
  border-radius: 5px;
  border: solid 1px #fa6c3e;
  color: #fa6c3e;
}
//捲軸底色
#scroll-bar::-webkit-scrollbar-track {
  background-color: #f8ece8;
  border-radius: 18px;
}
//捲軸寬度
#scroll-bar::-webkit-scrollbar {
  height: 6px;
}
//捲軸本體顏色
#scroll-bar::-webkit-scrollbar-thumb {
  background-color: #fa6c3e;
  border-radius: 18px;
}

//捲軸底色
.filterModalBody::-webkit-scrollbar-track {
  background-color: #f8ece8;
  border-radius: 18px;
}
//捲軸寬度
.filterModalBody::-webkit-scrollbar {
  width: 6px;
}
//捲軸本體顏色
.filterModalBody::-webkit-scrollbar-thumb {
  background-color: #fa6c3e;
  border-radius: 18px;
}
</style>
